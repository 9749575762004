import { applyMiddleware, createStore, combineReducers } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import { persistStore } from "redux-persist"
import reducer from "./reducer"

const rootReducer = combineReducers({
  auth: reducer,
})

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

export const persistor = persistStore(store, null)
