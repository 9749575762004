import * as types from "../actions/actionTypes"

const reducer = (
  state = {
    test: true,
    isTablet:
      typeof window != "undefined"
        ? window.innerWidth > 768 && window.innerWidth <= 1200
        : false,
    isTabletSlim:
      typeof window != "undefined"
        ? window.innerWidth > 768 && window.innerWidth <= 1100
        : false,
    isMobile: typeof window != "undefined" ? window.innerWidth <= 768 : false,
  },
  { type, payload }
) => {
  if (type === types.LOGOUT) {
    state = null
    return state
  }
  // if (type === types.HANDLE_RESPONSIVE) {
  //   return {
  //     ...state,
  //     isMobile: payload[0],
  //     isTablet: payload[1],
  //     isTabletSlim: payload[2],
  //   }
  // }
  return state
}

export default reducer
