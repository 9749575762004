import React from "react"
import { ThemeProvider } from "styled-components"
import { YoungProvider } from "@youngagency/young-ui"
import { store } from "./src/context/store"
import { Provider } from "react-redux"

const rootWrapper = ({ element }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={{ layout: "light" }}>
        <YoungProvider>{element}</YoungProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default rootWrapper
